<template>
    <div>
      <b-container class="mt-5 mb-5">
        <h1 class="fs-4 font-weight-bold mb-4">Nieuw bericht</h1>
        

        <ValidationObserver ref="form1" slim>
            <b-card class="mb-3">
                <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="mb-3">
                        <label class="required">Onderwerp</label>
                        <b-form-input v-model="form.subject" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>

                <div class="mb-3">
                    <label class="required">Verstuur op</label>
                    <b-row>
                        <b-col md="8">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <div class="mb-3">
                                    <label>Datum</label>
                                    <b-form-input v-model="form.schedule_at_date" type="date" max="9999-12-31" />
                                    <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                </div>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="4">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <div class="mb-3">
                                    <label>Tijdstip</label>
                                    <b-form-input v-model="form.schedule_at_time" type="time" />
                                    <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                </div>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                </div>

                <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="mb-3">
                        <label class="required">Evenement annuleren?</label>
                        <b-form-select v-model="form.is_cancelation" :options="yesNoOptions" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>

                <b-alert variant="warning" v-if="form.is_cancelation" show>
                    Je hebt gekozen om het evenement te annuleren. Na verzending worden alle bestellingen automatisch teruggeboekt.
                </b-alert>

                <div class="mb-3">
                    <label>Segmentatie inschakelen</label>
                    <b-form-select v-model="form.segments_enabled" :options="yesNoOptions" />
                </div>

                <template v-if="form.segments_enabled">
                    <b-card class="mb-3">
                        <div class="mb-3">
                            <label>Tickets</label>
                            <p class="text-muted">Stuur de e-mail naar iedereen die één van de volgende tickets besteld heeft.</p>
                            <b-form-select :options="ticketOptions" multiple />
                        </div>
                        <div class="mb-3">
                            <label>Soort bestelling</label>
                            <p class="text-muted">Stuur de e-mail bijv. alleen naar gasten.</p>
                            <b-form-select :options="segmentOptions" />
                        </div>
                    </b-card>
                </template>

                <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="mb-3">
                        <label class="required">Bericht</label>
                        <VueTrix v-model="form.message" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>
            </b-card>
            <div class="clearfix">
                <b-button :to="'/manage/events/' + $route.params.id + '/notifications'" variant="outline-primary" class="float-left">Annuleren</b-button>
                <b-button @click="submitForm" variant="primary" class="float-right">Verzenden</b-button>
            </div>
        </ValidationObserver>
      </b-container>
    </div>
</template>
  
<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    import VueTrix from "vue-trix";
    
    export default {
        metaInfo: {
            title: 'Bezoekers informeren',
        },
        components: {
            ValidationObserver,
            ValidationProvider,
            VueTrix
        },
        data() {
            return {
                yesNoOptions: [
                    {
                        'text': 'Ja',
                        'value': true
                    },
                    {
                        'text': 'Nee',
                        'value': false
                    }
                ],
                ticketOptions: [],
                form: {
                    subject: null,
                    sent_at_date: null,
                    sent_at_time: null,
                    is_cancelation: false,
                    segments_enabled: false,
                    message: null,
                },
            }
        },
        computed: {},
        methods: {
            async submitForm() {

                let check = await this.$refs['form1'].validate();

                if (!check) {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                    return;
                }

                this.$axios.post("https://api.tixgo.nl/events/" + this.$route.params.id + "/notifications", this.form)
                .then( response => {
                    response;
                    this.$router.push('/manage/events/' + this.$route.params.id + '/notifications');
                });
            }
        },
        created() {},
    }
</script>